import { format, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';
import logo from '../../../../assets/images/logo.jpg';
import { useReactToPrint } from 'react-to-print';
import BusinessInfo from '../../../Shared/BusinessInfo';

const ProfitStatement = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [salesProfit, setSalesProfit] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [opening, setOpening] = useState(0);
    const [details, setDetails] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const currentDate = new Date();
    const firstDate = startOfMonth(currentDate);
    const dateFormat = 'yyyy-MM-dd';
    const firstDateFormatted = format(firstDate, dateFormat);


    const givenDate = new Date(`${details?.startDate?details?.startDate:firstDateFormatted}`);
    const monthName = givenDate.toLocaleString('default', { month: 'long' });
    const year = givenDate.getFullYear();

    const options = {
        timeZone: 'Asia/Dhaka',
        hour12: false,
    };
    const currentTime = new Date().toLocaleString('en-US', options);

    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Profit Statement Of ${details?.accountName}`,

    })

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalancesales')
            .then(res => res.json())
            .then(data => {
                setSalesProfit(data[0]);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalanceincomes')
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(income => !income._id.includes("Capital")&&!income._id.includes("Opening"))
                setIncomes(filter);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalanceexpenses')
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(expense => !expense._id.includes("Fixed")&&!expense._id.includes("Profit"))
                setExpenses(filter);
                setLoading(false)
            })
    }, [])

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;


        const url = `https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalancesales?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSalesProfit(data[0]);
                setIsFilter(true);
                setIsLoaded(true);
                setDetails({startDate:startDate, range: startDate + " to " + endDate });
            })
        const url2 = `https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalanceincomes?startDate=${startDate}&endDate=${endDate}`
        fetch(url2)
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(income => !income._id.includes("Capital"));
                const filter2 = filter.filter(income => !income._id.includes("Opening"));
                setIncomes(filter2);
            });

        const url3 = `https://servers.thakurgaonexpress.com/sarabela-electric/getprofitbalanceexpenses?startDate=${startDate}&endDate=${endDate}`
        fetch(url3)
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(expense => !expense._id.includes("Fixed"));
                const filter2 = filter.filter(expense => !expense._id.includes("Profit"));
                setExpenses(filter2);
            })
    }


    const totalIncome = incomes?.reduce((total, currentValue) => total + parseInt(currentValue?.totalAmount), 0);
    const totalExpense = expenses?.reduce((total, currentValue) => total + parseInt(currentValue.totalAmount), 0);

    if (loading) {
        return <Loader />
    }
    return (
        <div className='mx-2'>
            <h2 className='mt-8 text-center text-3xl font-bold'>Profit Report</h2>
            <div className='flex justify-center items-center'>
                <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-center'>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Starting Date</span>
                        </label>
                        <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">End Date</span>
                        </label>
                        <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <input type='submit' value='Search' className='btn btn-md btn-error w-80 mx-auto lg:w-20 text-white mt-4 lg:mt-9 lg:ml-2' />
                    <div>
                    </div>
                </form>
            </div>
            <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Bank Statement- ${details.accountName}`}
                    sheet={`bankstatement-${details.accountName}`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            <div ref={componentRef}>
            <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full my-px" />
                    <hr class="border-b border-gray-300 w-full my-px" />
                </div>
                
                <h1 className='text-lg font-bold text-center mt-8'>Profit Sheet For the Month ({monthName}-{year})</h1>
                <div className='text-left ml-20 mt-8 text-xs'>
                    <h4>Report Type: <span className='font-bold'>Profit Sheet</span></h4>
                    <h4>Report Date Range: {details?.range ? details?.range : firstDateFormatted + " to " + today}</h4>
                    <h4>Report Time: {currentTime}</h4>
                </div>
                <div className='mb-20'>
                    <div class="overflow-x-auto mt-4 text-xs">
                        <table id='table-to-xls' class="table w-full text-center shadow-xl text-xs rounded-lg">
                            <thead>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Income/Expense Head</th>
                                    <th className='bg-red-600 text-white normal-case'>Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Amount</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td className='border border-red-600'>1</td>
                                    <td className='border border-red-600 text-left'>Total Sale/Profit</td>
                                    <td className='border border-red-600'>{salesProfit?.totalSales}</td>
                                    <td className='border border-red-600'>{(salesProfit?.totalSales - salesProfit?.totalProfit).toFixed(2)}</td>
                                </tr>

                            </tbody>
                            <tbody>
                                {
                                    incomes.map((income, index) => <tr>
                                        <td className='border border-red-600'>{index + 2}</td>
                                        <td className='border border-red-600 text-left'>{income._id}</td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'>{income.totalAmount}</td>
                                    </tr>)
                                }

                            </tbody>
                            <tbody>
                                <tr>
                                    <td className='border border-red-600 text-right font-bold' colSpan="3">Total Profit</td>
                                    <td className='border border-red-600 font-bold'>{(salesProfit?.totalSales - salesProfit?.totalProfit + totalIncome).toFixed(2)}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className='border border-red-600 text-right font-bold' colSpan="3">Total Expenses</td>
                                    <td className='border border-red-600 font-bold'>{totalExpense}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className='border border-red-600 text-right font-bold' colSpan="3">Grand Profit</td>
                                    <td className='border border-red-600 font-bold underline decoration-double'>{(salesProfit?.totalSales - salesProfit?.totalProfit + totalIncome - totalExpense).toFixed(2)}</td>
                                </tr>
                            </tbody>
                            <br />
                            <tbody>
                                {
                                    expenses.map((income, index) => <tr>
                                        <td className='border border-red-600'>{index + 1}</td>
                                        <td className='border border-red-600 text-left'>{income._id}</td>
                                        <td className='border border-red-600'>{income.totalAmount}</td>
                                        <td className='border border-red-600'>{income.details}</td>
                                    </tr>)
                                }

                            </tbody>
                            <tbody>
                                <tr>
                                    <td className='border border-red-600 text-right font-bold' colSpan="2">Total Expenses</td>
                                    <td className='border border-red-600 font-bold'>{totalExpense}</td>
                                    <td className='border border-red-600 font-bold'></td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white text-sm'></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>

            </div>


            {/* {isLoaded && <div ref={componentRef} style={{width:"100%"}} className='flex justify-center items-center mt-8'>
                <div>
                    <img src={logo} className="w-72 mr-20" alt="" />
                </div>
                <div className='mr-40'>
                    <h1 className='text-6xl text-black text-center'>M/S Nazmul Electric and Electronics</h1>
                    <h1 className='text-3xl text-black text-center'>Mawna Chowrasta, Sreepur, Gazipur</h1>
                    <h1 className='text-xl text-black text-center'>Mobile: 01404010532,01404010533</h1>
                </div>
            </div>}
            {isLoaded && <div class="flex items-center justify-between">
                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
            </div>}
            {isLoaded && <div class="flex items-center justify-between">
                <hr class="border-b border-gray-300 w-full my-px" />
                <hr class="border-b border-gray-300 w-full my-px" />
            </div>}
            {isLoaded && <div className='text-left'>
                <h4>Report Type: Bank Statement</h4>
                <h4>Account Name: {details?.accountName}</h4>
                <h4>Account No: {details?.accountno}</h4>
                <h4>Report Date Range: {details?.range}</h4>
                <h4>Report Time: {details?.time}</h4>
            </div>}
            {isLoaded && <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                    table="table-to-xls"
                    filename={`Bank Statement- ${details.accountName}`}
                    sheet={`bankstatement-${details.accountName}`}
                    buttonText="Export to exel" />
            </div>}
            {isLoaded && <div className='mb-20'>
                <div class="overflow-x-auto mt-8 text-xs">
                    <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                        <thead>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                <th className='bg-red-600 text-white normal-case'>Name</th>
                                <th className='bg-red-600 text-white normal-case'>Type</th>
                                <th className='bg-red-600 text-white normal-case'>Deposit Amount</th>
                                <th className='bg-red-600 text-white normal-case'>Withdraw Amount</th>
                                <th className='bg-red-600 text-white normal-case'>Details</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                bankstatement.map((income, index) => <tr>
                                    <td className='border border-red-600'>{index + 1}</td>
                                    <td className='border border-red-600'>{income.date}</td>
                                    <td className='border border-red-600'>{income.invoice}</td>
                                    <td className='border border-red-600'>{income.name}</td>
                                    <td className='border border-red-600'>{income.type}</td>
                                    <td className='border border-red-600'>{income.income === 0 ? "-" : income.income}</td>
                                    <td className='border border-red-600'>{income.expense === 0 ? "-" : income.expense}</td>
                                    <td className='border border-red-600'>{income.details}</td>
                                </tr>)
                            }

                        </tbody>
                        <tfoot>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'></th>
                                <th className='bg-red-600 text-white text-sm'>Opening Balance On</th>
                                <th className='bg-red-600 text-white text-sm'>{details?.startDate}</th>
                                <th className='bg-red-600 text-white text-sm'>{opening}</th>
                                <th className='bg-red-600 text-white text-sm normal-case'>Total</th>
                                <th className='bg-red-600 text-white text-sm'>{totalIncome}</th>
                                <th className='bg-red-600 text-white text-sm'>{totalExpense}</th>
                                <th className='bg-red-600 text-white text-sm normal-case'>Closing: {opening + totalIncome - totalExpense}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>} */}
        </div>
    );
};

export default ProfitStatement;