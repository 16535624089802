import React, { useEffect, useState } from 'react';
import sale from '../../../../src/assets/images/sale.png'

const ThisMonthSales = () => {
    const [sales, setSales] = useState([]);

    let currentDate = new Date();

    // Get the current month and year as strings
    let monthName = currentDate.toLocaleString('default', { month: 'long' });
    let yearName = currentDate.toLocaleString('default', { year: 'numeric' });
    const month = monthName + " " + yearName
    useEffect(() => {
        fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getmonthwisesales`)
            .then(res => res.json())
            .then(data => {
                const filtered = data.filter(sale => sale.month === month);
                setSales(filtered[0]);
            }
            )
    }, [])


    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden bg-[#E7FCE9] shadow-lg glassmorphism p-5">
            <div className='flex justify-between items-center'>
                <div class="flex flex-col justify-center items-center">
                    <div class="text-sm font-semibold mb-2">This Month Sales</div>
                    <p class="text-xl text-black">৳ {sales?.sales ? (sales?.sales)?.toFixed(2) : 0.00}</p>
                </div>
                <div class="">
                    <img src={sale} alt="Card image" class="mx-auto my-auto h-20 w-20 rounded-full border-4 border-red-600 object-cover" />
                </div>
            </div>
        </div>
    );
};

export default ThisMonthSales;