import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectPosSupplier } from '../../../../features/PurchaseSlice/PurchaseSlice';

const SupplierList = ({ suppliers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showSupplierList, setShowSupplierList] = useState(false);
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '') {
      setShowSupplierList(false); // Hide the customer list when no search term is entered
      setFilteredSuppliers([]); // Clear the filtered results
    } else {
      const filtered = suppliers.filter((customer) =>
        customer.name.toLowerCase().includes(term.toLowerCase())
      );

      setFilteredSuppliers(filtered);
      setShowSupplierList(true); // Show the customer list when there is a search term
    }
  };

  const handleSelectSupplier = (sup) => {
    const opening = parseFloat(sup?.opening)
    const url = `https://servers.thakurgaonexpress.com/sarabela-electric/filteredsupplier?code=${sup?.code}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        console.log(data);
        const due = opening + data?.defaultTotalDue - data?.defaultTotalPayment;
        dispatch(selectPosSupplier({ ...sup, due,showSupplierList }))
      })
    setShowSupplierList(false);
    setSearchTerm('')
  }

  return (
    <div className='text-center text-xs mt-2'>
      <input
        type="text"
        placeholder="Search Suppliers..."
        className="w-60 p-2 border rounded-lg "
        value={searchTerm}
        onChange={handleSearch}
      />

      {showSupplierList && ( // Conditional rendering to show/hide customer list
        <ul className="mt-4 absolute bg-white border h-40 w-60 overflow-auto rounded-lg p-2 z-20">
          {filteredSuppliers.map((supplier) => (
            <li onClick={() => handleSelectSupplier(supplier)} key={supplier.id} className="mb-2 text-xs bg-red-600 text-white font-bold p-3 cursor-pointer
             hover:bg-white hover:text-red-600 hover:border hover:border-1 hover:border-red-600 hover:transition-transform">
             {supplier.code} - {supplier.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SupplierList;
