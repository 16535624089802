import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editunit } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';



const UnitField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [units, setUnits] = useState([]);
    const [updateUnit, setUpdateUnit] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/getunits')
            .then(res => res.json())
            .then(data => {
                setUnits(data);
                setLoading(false)
            })
    }, [units, setUnits, updateUnit, setUpdateUnit, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0]
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/units', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Unit Added')
                reset();
            })

    }

    const handleDeleteunit = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getunits/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateUnit(null);
                })
        }
    };

    const handleupdateUnit = (unit) => {

        // fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getunits/${unit._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editunit(data))
        //     })
        setUpdateUnit(unit);
    }

    const handleApprove=(unit)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getunits/${unit._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Unit Approved');
                // window.location.reload(false)
            })
    }
    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Unit Type</span>
                    </label>
                    <input {...register("type")} type="text" placeholder="Type Unit Type Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addUnit} type="submit" value='Add unit' className='btn bg-red-600 text-white lg:mt-9' />
            </form>

            {
                units.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Unit Type</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                units.map((unit, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{unit.type}</td>
                                    <td> <button className={`btn btn-xs normal-case ${unit.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{unit?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{unit?.user ? unit.user : "User"}</button></td>
                                    {userAc.approvalUnit? <td>{unit?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{unit?.approvedBy}</button>: <button onClick={()=>handleApprove(unit)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{unit.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{unit.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editUnit && <td><button><label for="update-modal" onClick={() => handleupdateUnit(unit)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteUnit && <td><button onClick={() => handleDeleteunit(unit._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateUnit && <UpdateModal setUpdateUnit={setUpdateUnit} updateUnit={updateUnit}></UpdateModal>
            }
        </div>
    );
};

export default UnitField;