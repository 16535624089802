import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Shared/Loader';
import { useRef } from 'react';
import AddPurchaseProductList from './AddPurchaseProductList';
import AddPurchaseInvoice from './AddPurchaseInvoice';
import { addUpdateDetails, selectSupplier } from '../../../../features/PurchaseSlice/PurchaseSlice';


const UpdateSalesInvoice = () => {
    // const { discount, dueAmount, grandTotal, payment, products, purchaseDate, purchaseInvoice, purchaseSupplier, totalAmount } = updateModal;

    const supply = useSelector((state) => state.purchaseInvoice.supplier);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [loading, setLoading] = useState(true);
    const [suppliers, setSuppliers] = useState([]);
    // const [details, setDetails] = useState();
    const [defaultSupplier, setDefaultSupplier] = useState(false);

    const { updatePurchase } = useSelector((state) => state.purchaseInvoice);

    console.log(updatePurchase);

    const supplierRef = useRef('');
    const dateRef = useRef('');
    const addressRef=useRef('');
    const phoneRef=useRef('');
    const openingRef=useRef('');
    const invoiceRef=useRef('');

    const dispatch = useDispatch();



    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/getsuppliers')
            .then(res => res.json())
            .then(data => {
                setSuppliers(data);
                setLoading(false)
            })
    }, [])


    if (loading) {
        return <Loader />
    }

    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = suppliers.filter(supplier => supplier.name.includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleSelectSupplier = (suppliar) => {
        dispatch(selectSupplier(suppliar))
        setIsSearching(false)
        setSearchText('');
        setDefaultSupplier(true)

        setTimeout(()=>{handlePurchaseDetails()}, 2000);
        // const purchaseInvoice = invoiceRef.current.value;
        // const purchaseSupplier = supplierRef.current.innerText;
        // const purchaseAddress = addressRef.current.innerText;
        // const purchasePhone = phoneRef.current.innerText;
        // const purchaseOpening = openingRef.current.innerText;
        // const purchaseDate = dateRef.current.value;
        // dispatch(addUpdateDetails({ purchaseInvoice, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate }))

    }


    const handlePurchaseDetails = (event) => {
        const purchaseInvoice = invoiceRef.current.value;
        const purchaseSupplier = supplierRef.current.innerText;
        const purchaseAddress = addressRef.current.innerText;
        const purchasePhone = phoneRef.current.innerText;
        const purchaseOpening = openingRef.current.innerText;
        const purchaseDate = dateRef.current.value;
        // setDetails({ purchaseInvoice, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate })
        dispatch(addUpdateDetails({ purchaseInvoice, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate }))
    }


    return (
        <div>
            <div className='w-full px-12 pb-5 my-5'>
                <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Update Purchase</h1>
                <div className='grid grid-cols-12 gap-4'>
                    <div class="form-control w-60 max-w-xs mt-5 col-span-2">
                        <div class="form-control w-60">
                            <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Supplier" class="rounded-lg bg-white p-3 text-white text-center placeholder-black focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-16' : "h-8"} w-80 overflow-auto`}>
                            {
                                isSearching ? searchResult.map(supplier =>
                                    <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier?.name}</label>) :
                                    suppliers.map(supplier =>
                                        <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier?.name}</label>)
                            }
                        </div>
                    </div>
                    <div className='col-span-6 mt-4'>
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Supplier Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Supplier Address</th>
                                    <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                    <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className=''>
                                    <td onChange={handlePurchaseDetails} ref={supplierRef} className='h-8'>{defaultSupplier === true ? supply?.name : updatePurchase?.purchaseSupplier}</td>
                                    <td ref={addressRef}>{defaultSupplier === true ? supply?.address : updatePurchase?.purchaseAddress}</td>
                                    <td ref={phoneRef}>{defaultSupplier === true ? supply?.phone : updatePurchase?.purchasePhone}</td>
                                    <td ref={openingRef}>{defaultSupplier === true ? supply?.opening : updatePurchase?.purchaseOpening}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='col-span-4 mt-4'>
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className=''>
                                    <td><input onChange={handlePurchaseDetails} ref={dateRef} type="date" value={updatePurchase?.purchaseDate} className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                    <td><input onChange={handlePurchaseDetails} ref={invoiceRef} type="text" defaultValue={updatePurchase?.purchaseInvoice} className='rounded-lg bg-gray-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='grid grid-cols-1 relative'>
                    <AddPurchaseProductList />
                    <AddPurchaseInvoice />
                </div>
            </div>
        </div>
    );
};

export default UpdateSalesInvoice;