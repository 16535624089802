import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { editSupplier } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import usePaginationSearch from '../../../hooks/usePaginationSearch';
import ReactPaginate from 'react-paginate';
import useFetchData from '../../../hooks/useFetchData';



const SupplierField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const setting = useSelector((state) => state.info.settings);
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick, totalItem, refetchData } = usePaginationSearch('https://servers.thakurgaonexpress.com/sarabela-electric/getsupplierswithfilter')
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [updateSupplier, setUpdateSupplier] = useState(null);
    const dispatch = useDispatch();
    const [isExpend, setIsExpend] = useState(false);
    const supplierNoRef = useRef('');
    const { items: suppliers } = useFetchData('https://servers.thakurgaonexpress.com/sarabela-electric/getsuppliers');

    // const supplierCode = (totalItem + 1).toString().padStart(5, '0');
    const [supplierNo, setSupplierNo] = useState('');
    useEffect(() => {
        if (setting?.Auto_Supplier_Code) {
            const myLength = 'SUP-' + (suppliers?.length + 1).toString().padStart(5, '0');
            console.log(myLength);
            const isExist = suppliers?.filter(cus => cus?.code === myLength);
            const duplicate = isExist?.length ? true : false;

            if (duplicate) {
                // Increment the suffix to avoid duplicates
                const suffix = isExist.length + 1;
                setSupplierNo(`${myLength}-${suffix}`);
            } else {
                setSupplierNo(myLength);
            }
        } else {
            setSupplierNo('');
        }
    }, [suppliers]);

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        const code = supplierNoRef?.current?.value;
        // console.log(code);
        fetch('https://servers.thakurgaonexpress.com/sarabela-electric/suppliers', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ user, code, ...data }),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Supplier Added')
                reset();
                refetchData();
                setIsExpend(false);
                setTimeout(() => { window.location.reload(); }, 3000);
            })

    }

    const handleDeleteSupplier = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getsuppliers/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateSupplier(null);
                    refetchData(pageNo);
                    setTimeout(() => { window.location.reload(); }, 2000);
                })
        }
    };

    const handleUpdateSupplier = (supplier) => {

        // fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getsuppliers/${Supplier._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editSupplier(data))
        //     })
        setUpdateSupplier(supplier);
    }

    const handleApprove = (supplier) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/sarabela-electric/getsuppliers/${supplier._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Supplier Approved');
                refetchData(pageNo);
            })
    }

    return (
        <div>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-error text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Supplier</button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center ${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Supplier Code</span>
                        </label>
                        <input ref={supplierNoRef} {...(setting?.Auto_Supplier_Code ? { value: supplierNo } : { defaultValue: '' })} type="text" placeholder="Type Supplier Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Supplier Name</span>
                        </label>
                        <input {...register("name")} type="text" placeholder="Type Supplier Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Address</span>
                        </label>
                        <input {...register("address")} type="text" placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Phone No</span>
                        </label>
                        <input {...register("phone")} type="text" placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Opening Balance</span>
                        </label>
                        <input {...register("opening")} type="text" placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addSupplier} type="submit" value='Add Supplier' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-12' />
                </form>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-between items-center my-4'>
                        <div class="form-control w-28  max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Show</span>
                            </label>
                            <select onChange={handleLimit} defaultValue='' class="text-center text-xs rounded-lg text-black select select-bordered select-error w-full max-w-xs" required>
                                <option selected >20</option>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>All</option>
                            </select>
                        </div>
                        <div class="form-control w-60 mt-9 ml-2 lg:ml-0">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Supplier" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                    {
                        items.length ? <div class="overflow-x-auto mt-8">
                            <table class="table w-full mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'>SL</th>
                                        <th className='bg-red-600 text-white normal-case'>Supplier Code</th>
                                        <th className='bg-red-600 text-white normal-case'>Supplier Name</th>
                                        <th className='bg-red-600 text-white normal-case'>Address</th>
                                        <th className='bg-red-600 text-white normal-case'>Phone</th>
                                        <th className='bg-red-600 text-white normal-case'>Opening</th>
                                        <th className='bg-red-600 text-white normal-case'>Status</th>
                                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                                        <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                        <th className='bg-red-600 text-white normal-case'>Edit</th>
                                        <th className='bg-red-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        items.map((supplier, index) => <tr className='hover'>
                                            <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                                            <td>{supplier.code}</td>
                                            <td>{supplier.name}</td>
                                            <td>{supplier.address}</td>
                                            <td>{supplier.phone}</td>
                                            <td>{supplier.opening}</td>
                                            <td> <button className={`btn btn-xs normal-case ${supplier.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{supplier?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{supplier?.user ? supplier.user : "User"}</button></td>
                                            {userAc.approvalSupplier ? <td>{supplier?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{supplier?.approvedBy}</button> : <button onClick={() => handleApprove(supplier)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{supplier.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{supplier.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editSupplier && <td><button><label for="update-modal" onClick={() => handleUpdateSupplier(supplier)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteSupplier && <td><button onClick={() => handleDeleteSupplier(supplier._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : ""
                    }
                    {limit === "All" ? "" : <div className='my-8 text-xs'>
                        <ReactPaginate
                            forcePage={pageNo - 1}
                            previousLabel={'Pre'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'btn-group justify-center'}
                            pageClassName={'btn btn-error text-white'}
                            activeClassName={'btn bg-white text-black'}
                            previousClassName={'btn btn-error text-white'}
                            nextClassName={'btn btn-error text-white'}
                            breakClassName={'btn btn-error text-white'}></ReactPaginate>
                    </div>}
                </div>
            </div>
            {
                updateSupplier && <UpdateModal refetchData={refetchData} pageNo={pageNo} setUpdateSupplier={setUpdateSupplier} updateSupplier={updateSupplier}></UpdateModal>
            }

        </div>
    );
};

export default SupplierField;