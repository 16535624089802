import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import profitimg from '../../../../src/assets/images/profit.png'
import useFetchData from '../../../hooks/useFetchData';

const TodaySales = () => {
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const { items: profit } = useFetchData("https://servers.thakurgaonexpress.com/sarabela-electric/daywiseprofit");

    const todayProfit = profit.filter(pf => pf.day === today);


    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden bg-[#FFE6FF] shadow-lg glassmorphism p-5">
            <div className='flex justify-between items-center'>
                <div class="flex flex-col justify-center items-center">
                    <div class="text-sm font-semibold mb-2">Today Profit</div>
                    <p class="text-xl text-black">৳ {todayProfit[0]?.profit ? (todayProfit[0]?.profit).toFixed(2) : 0.00}</p>
                </div>
                <div class="">
                    <img src={profitimg} alt="Card image" class="mx-auto my-auto h-20 w-20 rounded-full border-4 border-red-600 object-cover" />
                </div>
            </div>
        </div>
    );
};

export default TodaySales;